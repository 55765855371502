import "./login.css";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef } from "react";
import { auth, logInWithEmailAndPassword } from "../../../firebase";

export default function Login() {
  const emailRef = useRef();
  const passwordRef = useRef();
  const [user, loading] = useAuthState(auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return;
    }
    // if (!user) {
    //   registerWithEmailAndPassword(
    //     "Biniyam",
    //     "biniabera274@gmail.com",
    //     "biniabera274"
    //   );
    // }
    if (user) navigate("/");
  }, [user, loading, navigate]);

  return (
    <div className="login">
      <span className="loginTitle">Login</span>
      <form
        className="loginForm"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <label>Email</label>
        <input
          type="text"
          className="loginInput"
          placeholder="Enter your email..."
          ref={emailRef}
        />
        <label>Password</label>
        <input
          type="password"
          className="loginInput"
          placeholder="Enter your password..."
          ref={passwordRef}
        />
        <button
          className="loginButton"
          type="submit"
          onClick={() =>
            logInWithEmailAndPassword(
              emailRef.current.value,
              passwordRef.current.value
            )
          }
          disabled={loading}
        >
          Login
        </button>
      </form>
    </div>
  );
}
