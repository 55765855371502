import React, { useEffect, useRef, useState } from "react";
import {
  Table,
  Card,
  Button,
  Modal,
  FloatingLabel,
  Image,
  ProgressBar,
  Form,
} from "react-bootstrap";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link, useNavigate } from "react-router-dom";
import { auth, db, storage } from "../../../firebase";
import {
  Timestamp,
  addDoc,
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  orderBy,
  query,
  updateDoc,
} from "firebase/firestore";
import { Editor } from "@tinymce/tinymce-react";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";

export default function Write() {
  const [user, loading] = useAuthState(auth);
  const navigate = useNavigate();
  const [posts, setPosts] = useState([]);
  const [menuCategories, setMenuCategories] = useState([]);
  const [uploading, setUploading] = useState(false);
  const descRef = useRef();
  const [title, setTitle] = useState("");
  const [category, setCategory] = useState("");
  const [currentMenuItemId, setCurrentMenuItemId] = useState("");
  const [currentMenuItem, setCurrentMenuItem] = useState("");

  const [imgUrl, setImgUrl] = useState(null);
  const [progresspercent, setProgresspercent] = useState(0);
  const [showAddEditForm, setShowAddEditForm] = useState(false);
  const [showAddEditCategory, setShowAddEditCategory] = useState(false);
  const [addEditFormType, setAddEditFormType] = useState("Add"); //Add, Edit
  const [validated, setValidated] = useState(false);

  const handleModalClose = () => {
    setShowAddEditForm(false);
    setAddEditFormType("Add");
    setShowAddEditForm(false);
    setShowAddEditCategory(false);
  };

  useEffect(() => {
    console.log({ user });

    if (loading) {
      // maybe trigger a loading screen
      return;
    }
    if (!user) {
      navigate("/login");
    }
  }, [user, loading, navigate]);

  useEffect(() => {
    const postQ = query(collection(db, "posts"), orderBy("created", "desc"));
    onSnapshot(postQ, (querySnapshot) => {
      setPosts(
        querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
      );
    });
    const catQ = query(collection(db, "categories"));
    onSnapshot(catQ, (querySnapshot) => {
      const cats = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setMenuCategories(cats);
    });
  }, []);

  const handleDelete = async (id) => {
    const taskDocRef = doc(db, "posts", id);
    try {
      await deleteDoc(taskDocRef);
      window.location.replace("/");
    } catch (err) {
      alert(err);
    }
  };

  const handleDeleteCat = async (id) => {
    const catDocRef = doc(db, "categories", id);

    try {
      await deleteDoc(catDocRef);
      window.location.replace("/");
    } catch (err) {
      alert(err);
    }
  };

  const handleUpload = (e) => {
    setUploading(true);
    e.preventDefault();
    const file = e.target.files[0];
    if (!file) return;
    const storageRef = ref(storage, `files/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgresspercent(progress);
      },
      (error) => {
        alert(error);
        setUploading(false);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setImgUrl(downloadURL);
        });
        setUploading(false);
      }
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log({
      title,
      category,
      image: imgUrl,
      created: Timestamp.now(),
      currentMenuItemId,
    });

    const newPost = {
      title,
      category,
      image: imgUrl,
      created: Timestamp.now(),
      desc: descRef.current.getContent(),
    };
    try {
      if (addEditFormType === "Edit") {
        await updateDoc(doc(db, "posts", currentMenuItemId), newPost);
      } else {
        await addDoc(collection(db, "posts"), newPost);
      }
    } catch (err) {
      alert(err);
    }

    window.location.reload(false);
    handleModalClose();
  };

  const handleCategorySubmit = async (e) => {
    e.preventDefault();

    const newCategory = {
      name: title,
    };
    try {
      if (addEditFormType === "Edit") {
        await updateDoc(doc(db, "categories", currentMenuItemId), newCategory);
      } else {
        await addDoc(collection(db, "categories"), newCategory);
      }
    } catch (err) {
      alert(err);
    }

    window.location.reload(false);
    handleModalClose();
  };

  return (
    <>
      <Card style={{ margin: 24 }}>
        <Card.Header className="d-flex justify-content-between align-items-center">
          <Button
            onClick={() => {
              setShowAddEditForm(true);
            }}
            style={{ backgroundColor: "#B87B2B", borderWidth: 0 }}
          >
            Create Post
          </Button>
          <Button
            onClick={() => {
              setShowAddEditCategory(true);
            }}
            style={{ backgroundColor: "#B87B2B", borderWidth: 0 }}
          >
            Create Category
          </Button>
        </Card.Header>
        <Card.Body>
          <Table responsive>
            <thead>
              <tr>
                <th>#</th>
                <th>Title</th>
                <th>Description</th>
                <th>Created At</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {posts.map((p, idx) => (
                <tr key={p.id}>
                  <td>{idx}</td>
                  <td>{p.title}</td>
                  <td>
                    {p.desc.length > 150
                      ? `${p.desc.substring(0, 150)}...`
                      : p.desc}
                  </td>
                  <td>{p.created.toDate().toISOString()}</td>
                  <td>
                    <tr>
                      <td>
                        <Link to={`post/${p.id}`}>Veiw</Link>{" "}
                      </td>
                      |
                      <td>
                        <Button
                          onClick={() => {
                            setCurrentMenuItemId(p.id);
                            setCurrentMenuItem(p);
                            setImgUrl(p.image);
                            setTitle(p.title);
                            setCategory(p.category);
                            setAddEditFormType("Edit");
                            setShowAddEditForm(true);
                          }}
                          style={{ backgroundColor: "black", borderWidth: 0 }}
                        >
                          Edit
                        </Button>
                      </td>
                      |
                      <td>
                        <Button
                          onClick={(e) => {
                            e.preventDefault();
                            handleDelete(p.id);
                          }}
                          style={{ backgroundColor: "#BD2B2B", borderWidth: 0 }}
                        >
                          Delete
                        </Button>
                      </td>
                    </tr>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          <Table responsive>
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {menuCategories.map((p, idx) => (
                <tr key={p.id}>
                  <td>{idx}</td>
                  <td>{p.name}</td>

                  <td>
                    <tr>
                      <td>
                        <Button
                          onClick={() => {
                            setCurrentMenuItemId(p.id);
                            setCurrentMenuItem(p);
                            setAddEditFormType("Edit");
                            setShowAddEditCategory(true);
                          }}
                          style={{ backgroundColor: "black", borderWidth: 0 }}
                        >
                          Edit
                        </Button>
                      </td>
                      |
                      <td>
                        <Button
                          onClick={(e) => {
                            e.preventDefault();
                            handleDeleteCat(p.id);
                          }}
                          style={{ backgroundColor: "#BD2B2B", borderWidth: 0 }}
                        >
                          Delete
                        </Button>
                      </td>
                    </tr>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
        <Card.Footer className="d-flex justify-content-between align-items-center">
          <p style={{ marginTop: 8, fontSize: 12, color: "#A1A1A1" }}>© 2023</p>
          <p style={{ marginTop: 8, fontSize: 12, color: "#A1A1A1" }}>
            <a href="/login"> Login</a>
          </p>
        </Card.Footer>
      </Card>

      {/* Add/Edit Form START */}
      <Modal show={showAddEditForm} onHide={handleModalClose}>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Modal.Header>
            <Modal.Title>
              {addEditFormType === "Add" ? "Add Post" : "Edit"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {imgUrl || currentMenuItem.image ? (
              <Image src={imgUrl || currentMenuItem.image} thumbnail />
            ) : (
              <ProgressBar now={progresspercent} />
            )}
            {addEditFormType === "Edit" ? currentMenuItem.title : ""}
            <Form.Control
              type="file"
              accept="image/*"
              name="image"
              onChange={handleUpload}
            />
            <FloatingLabel controlId="title" label="Title" className="mb-3">
              <Form.Control
                required
                type="text"
                placeholder="Enter Title"
                size="md"
                feedback="Error"
                defaultValue={
                  addEditFormType === "Edit" ? currentMenuItem.title : title
                }
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
              />
            </FloatingLabel>
            <FloatingLabel
              controlId="itemCategory"
              label="Category"
              className="mb-3"
            >
              <Form.Control
                as="select"
                defaultValue={
                  addEditFormType === "Edit"
                    ? currentMenuItem.category
                    : category
                }
                onChange={(e) => setCategory(e.target.value)}
              >
                <option value="">All</option>
                {menuCategories &&
                  menuCategories.map((menuCategory, index) => (
                    <option
                      key={`select-${index}`}
                      value={menuCategory.id}
                      selected={category && menuCategory.id == category}
                    >
                      {`${menuCategory.name}`}
                    </option>
                  ))}
              </Form.Control>
            </FloatingLabel>

            <FloatingLabel
              controlId="description"
              label="Description"
              className="mb-3"
            >
              <Editor
                apiKey="your-api-key"
                onInit={(evt, editor) => (descRef.current = editor)}
                initialValue={
                  addEditFormType === "Edit" ? currentMenuItem.desc : ""
                }
                init={{
                  height: 700,
                  width: "100%",
                  menubar: false,
                  plugins: [
                    "advlist",
                    "autolink",
                    "lists",
                    "link",
                    "image",
                    "charmap",
                    "preview",
                    "anchor",
                    "searchreplace",
                    "visualblocks",
                    "code",
                    "fullscreen",
                    "insertdatetime",
                    "media",
                    "table",
                    "code",
                    "help",
                    "wordcount",
                  ],
                  toolbar:
                    "undo redo | blocks | " +
                    "bold italic forecolor | alignleft aligncenter " +
                    "alignright alignjustify | bullist numlist outdent indent | " +
                    "removeformat | help",
                  content_style:
                    "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                }}
              />
            </FloatingLabel>
          </Modal.Body>
          <Modal.Footer>
            <Button type="submit">
              {addEditFormType === "Add" ? "Add" : "Update"}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      {/* Add/Edit Form END */}

      {/* Add/Edit Category START */}
      <Modal show={showAddEditCategory} onHide={handleModalClose}>
        <Form noValidate validated={validated} onSubmit={handleCategorySubmit}>
          <Modal.Header>
            <Modal.Title>
              {addEditFormType === "Add" ? "Add " : "Edit"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FloatingLabel controlId="title" label="Title" className="mb-3">
              <Form.Control
                required
                type="text"
                placeholder="Enter Name"
                size="md"
                feedback="Error"
                defaultValue={
                  addEditFormType === "Edit" ? currentMenuItem.name : title
                }
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
              />
            </FloatingLabel>
          </Modal.Body>
          <Modal.Footer>
            <Button type="submit">
              {addEditFormType === "Add" ? "Add" : "Update"}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      {/* Add/Edit Form END */}
    </>
  );
}
