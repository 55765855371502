import "./single.css";
import SinglePost from "../../../components/singlePost/SinglePost";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../../firebase";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

export default function Single() {
  const [user, loading] = useAuthState(auth);
  const navigate = useNavigate();

  useEffect(() => {
    console.log({ user });

    if (loading) {
      // maybe trigger a loading screen
      return;
    }
    if (!user) {
      navigate("/login");
    }
  }, [user, loading, navigate]);

  return <SinglePost />;
}
