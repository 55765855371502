import { Link } from "react-router-dom";
import "./topbar.css";
import { auth, logout } from "../../firebase";
import { useAuthState } from "react-firebase-hooks/auth";

export default function TopBar() {
  const [user, loading, error] = useAuthState(auth);

  return (
    <div className="top">
      <div className="topLeft">
        <Link to="/">
          <img className="topImg" src={`/images/logo.jpg`} alt="" />
        </Link>
      </div>
      <div className="topRight">
        {user ? (
          <ul className="topList">
            <li
              className="topListItem"
              onClick={() => {
                logout();
              }}
            >
              {user && "LOGOUT"}
            </li>
          </ul>
        ) : (
          <ul className="topList">
            <li className="topListItem">
              <Link className="link" to="/login">
                LOGIN
              </Link>
            </li>
          </ul>
        )}
      </div>
    </div>
  );
}
