import React from 'react';
import TopBar from './components/topbar/TopBar';
import Single from './pages/home/single/Single';
import Write from './pages/home/write/Write';
import Login from './pages/home/login/Login';

import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
function App() {
  return (
    <Router>
      <TopBar />
      <Routes>
        <Route exact path='/' element={<Write />} />
        <Route path='/login' element={<Login />} />
        <Route path='/post/:postId' element={<Single />}>
        </Route>
      </Routes>
      {/* <Single /> */}
    </Router>

  );
}
export default App;

