import { useLocation } from "react-router";
import "./singlePost.css";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { useEffect, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";

export default function SinglePost() {
  const location = useLocation();
  const path = location.pathname.split("/")[2];
  const [post, setPost] = useState({});
  useEffect(() => {
    getPost();
  }, [path]);

  const getPost = async () => {
    const postRef = doc(db, "posts", path);
    const docSnap = await getDoc(postRef);
    const data = docSnap.exists() ? docSnap.data() : null;
    if (data === null || data === undefined) return null;
    setPost({ id: path, ...data });
  };

  return (
    <div className="singlePost">
      {post ? (
        <div className="singlePostWrapper">
          <img src={post.image} alt={""} className="singlePostImg" />

          <div className="singlePostInfo">
            <span className="singlePostDate">
              {post.created ? post.created.toDate().toISOString() : <></>}
            </span>
          </div>
          <h1 className="singlePostTitle">{post.title}</h1>
          <div dangerouslySetInnerHTML={{ __html: post.desc }} />
          <hr />
          <p>
            በተሰማሩበት መስክ የሒዳያ ኢስላማዊ ማዕከል አባል በመኾን ዳዕዋውን ማገዝ ከፈለጉ በሚከተለው ማስፈንጠሪያ
            የአባልነት ቅፅ በመሙላት ሊቀላቀሉን ይችላሉ፦ የአባልነት ፎርሙን ለመሙላት ይህንን ይጫኑ ማዕከላችንን
            የሚሰራቸውን ስራዎች በቀጥታ ለመደገፍ፦
            <br />
            አካውንት – 1000499318212
            <br />
            የአካውንት ስም – Hidaya Islamic Center በአጭር ቁጥር – 8212 መጠቀም ይችላሉ።
          </p>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
